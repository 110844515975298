import React from 'react';

// Define a TypeScript interface for the link prop
interface LinkProps {
  id: string | number; // Allow id to be either string or number
  long_url: string;
  short_url: string;
  image?: string; // Optional property
  description?: string; // Optional property
}

interface LinkCardProps {
  link: LinkProps; // The link prop should be of type LinkProps
}

const LinkCard: React.FC<LinkCardProps> = ({ link }) => {
  const getFaviconUrl = (url: string) => {
    const domain = new URL(url).hostname;
    return `https://www.google.com/s2/favicons?domain=${domain}`;
  };

  const handleCopyLink = (link: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = link;
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const message = successful ? "Link copied to clipboard!" : "Copy failed";
      alert(message);
    } catch (err) {
      console.error('Copy failed', err);
    }

    document.body.removeChild(textArea);
  };

  return (
    <div className="p-20 shadow-lg m-4 rounded-lg mb-20 bg-paper" style={{ opacity: 1 }} key={link.id}>
      <img
        style={{
          width: '100%',
          height: '8rem',
          objectFit: 'contain',
          borderRadius: '8px',
          display: 'block'
        }}
        src={link.image ? link.image : 'images/logo-banner.png'}
        alt={`${link.long_url} favicon`}
      />
      
      <div className="flex items-center gap-8">
        <img
          className="w-16 h-16"
          src={getFaviconUrl(link.long_url)}
          alt={`${link.long_url} favicon`}
        />
        <a
          target="_blank"
          href={link.short_url}
          className="text-link hover:underline hover:text-primary-dark focus-visible:ring focus-visible:ring-2 focus-visible:ring-offset-2 outline-none rounded transition-colors font-medium"
          rel="noreferrer"
        >
          {`${link.long_url.substring(0, 25)}...`}
        </a>
      </div>
      <a
        href={link.short_url}
        className="focus-visible:ring whitespace-nowrap inline-flex select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default justify-center text-sm hover:underline"
      >
        {link.short_url}
      </a>
      
      <button
        onClick={() => handleCopyLink(link.short_url)}
        className="ml-2 px-2 py-1 text-sm rounded"
        style={{ marginLeft: '6px' }}
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="16" 
          height="16" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="#3b82f6" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          className="feather feather-copy"
        >
          <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
        </svg>
      </button>
      
      <p>{link.description ? `${link.description.substring(0, 60)}...` : ''}</p>
    </div>
  );
};

export default LinkCard;
