import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSettings } from '@common/core/settings/use-settings';
import { Link } from 'react-router-dom';
import { Trans } from '../../../common/resources/client/i18n/trans';
import { Navbar } from '../../../common/resources/client/ui/navigation/navbar/navbar';
import { StaticPageTitle } from '../../../common/resources/client/seo/static-page-title';
import { Footer } from '@common/ui/footer/footer';
import LinkCard from "./LinkCard";

interface Link {
  id: number;
  long_url: string;
  short_url: string; // Added short_url
  description: string;
  image: string;
}

interface LinkGroup {
  id: number;
  name: string;
}

const getFaviconUrl = (url: string | URL) => {
  try {
    const { hostname } = new URL(url);
    return `https://www.google.com/s2/favicons?domain=${hostname}`;
  } catch (error) {
    console.error('Invalid URL:', url);
    return '';
  }
};

const TrendingLink: React.FC = () => {
  const [linkGroups, setLinkGroups] = useState<LinkGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1); // Page state for pagination
  const [hasMore, setHasMore] = useState<boolean>(true); // Track if more items are available
  const [searchQuery, setSearchQuery] = useState<string>(""); 
  const { branding } = useSettings();

  useEffect(() => {
    fetchLinks(page); // Fetch links when the component mounts
    window.addEventListener('scroll', handleScroll); // Add scroll listener

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, [page]);

  const fetchLinks = async (page: number) => {
    if (loading) return; // Prevent multiple fetches during loading

    setLoading(true);
    try {
      const response = await axios.get<Link[]>(`/api/v1/trending-links?page=${page}&per_page=10`);
      if (response.data.length > 0) {
        setLinks((prevLinks) => [...prevLinks, ...response.data]); // Append new links
      } else {
        setHasMore(false); // No more items to load
      }
    } catch (error) {
      console.error('Error fetching links:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 &&
      hasMore &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1); // Increment page when user scrolls near the bottom
    }
  };

  // Filter links based on the search query
  const filteredLinks = links.filter(link =>
    link.long_url.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (link.description && link.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="flex flex-col bg-alt min-h-screen">
      <StaticPageTitle>
        <Trans message="Trending links" />
      </StaticPageTitle>
      <Navbar
        className="flex-shrink-0 sticky top-0"
        menuPosition="contact-us-page"
      />
      <div className="p-24 md:p-40 mx-auto flex-auto flex items-center justify-center">
        <div className="p-4 mb-2">
          <h3 className="text-center text-3xl mb-4 font-normal">Trending Links</h3>

          {/* Search Input */}
          <div className="mb-4 text-center">
            <input
              type="text"
              placeholder="Search links..."
              value={searchQuery}
              style={{
                width: '60%',
                margin: '1rem',
                height: '2.5rem',
                padding: '1rem',
                border: '2px solid black',
                borderRadius: '10rem'
              }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Links Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-4">
            <div className="col-span-12 p-4 m-4">
              {loading && page === 1 ? (
                <div className="flex justify-center items-center h-full">
                  <span>Loading...</span>
                </div>
              ) : filteredLinks.length === 0 && page === 1 ? (
                <div className="flex justify-center items-center h-full">
                  <span>No links found.</span>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                  {filteredLinks.map((link) => (
                    <LinkCard key={link.id} link={link} /> // Ensure you're passing the correct link object
                  ))}
                </div>
              )}
              {loading && page > 1 && (
                <div className="flex justify-center items-center h-full">
                  <span>Loading more...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer className="container mx-auto px-24 flex-shrink-0" />
    </div>
  );
};

export default TrendingLink;
