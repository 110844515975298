"use client"; // Add this line at the very top

import React, { useState, useEffect } from "react";
import axios from 'axios';
import {useSettings} from '@common/core/settings/use-settings';
import { Link } from 'react-router-dom'; // Ensure you are using react-router for navigation

import { useLocation } from 'react-router-dom';
import LinkCard from "./LinkCard";


interface Link {
  id: number;
  long_url: string;
  description: string;
  image: string;
  short_url: string;
}

interface LinkGroup {
  id: number;
  name: string;
}


const handleCopyLink = (link: string) => {
  // Create a temporary textarea element to hold the link
  const textArea = document.createElement("textarea");
  textArea.value = link;

  // Make sure the textarea is not visible and won't affect page layout
  textArea.style.position = "fixed";  // Ensure it's fixed to prevent scrolling to the element
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.width = "2em";
  textArea.style.height = "2em";
  textArea.style.padding = "0";
  textArea.style.border = "none";
  textArea.style.outline = "none";
  textArea.style.boxShadow = "none";
  textArea.style.background = "transparent";

  // Append the textarea to the body
  document.body.appendChild(textArea);

  // Select the content of the textarea
  textArea.focus();
  textArea.select();

  try {
    // Execute the copy command
    const successful = document.execCommand('copy');
    const message = successful ? "Link copied to clipboard!" : "Copy failed";
    alert(message);
  } catch (err) {
    console.error('Copy failed', err);
  }

  // Remove the textarea element after copying
  document.body.removeChild(textArea);
};

const getFaviconUrl = (url: string | URL) => {
  try {
    const { hostname } = new URL(url);
    return `https://www.google.com/s2/favicons?domain=${hostname}`;
  } catch (error) {
    console.error('Invalid URL:', url);
    return ''; 
  }
};

const limitDescription = (description: string, maxLength: number = 20): string => {
  if (description.length > maxLength) {
    return `${description.substring(0, maxLength)}...`;
  }
  return description; 
};

const LinkGroupTabs: React.FC = () => {
  const [linkGroups, setLinkGroups] = useState<LinkGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(""); // Add state for search query
  const {branding} = useSettings();

  const location = useLocation();
  const isHome = location.pathname === '/'; // Assuming '/' is your homepage path


  useEffect(() => {
    const fetchLinkGroups = async () => {
      setLoading(true);
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: '/api/v1/link-group-home',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      };

      try {
        const response = await axios.request<{ id: number; name: string }[]>(config);
        setLinkGroups(response.data);
        if (response.data.length > 0) {
          const initialGroupId = response.data[0].id;
          setSelectedGroup(initialGroupId);
          fetchLinks(initialGroupId);
        }
      } catch (error) {
        console.error('Error fetching link groups:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLinkGroups();
  }, []);

  const fetchLinks = async (groupId: number) => {
    setLoading(true);
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/link-group-home/${groupId}`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };

    try {
      const response = await axios.request<Link[]>(config);
      setLinks(response.data);
    } catch (error) {
      console.error('Error fetching links:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (groupId: number) => {
    setSelectedGroup(groupId);
    fetchLinks(groupId);
  };

  // Filter links based on the search query
  const filteredLinks = links.filter(link =>
    link.long_url.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (link.description && link.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="p-4 mb-2" style={{ marginBottom: '1rem' }}>

{!isHome && ( // Only render this block if not on the homepage
    <div style={{ textAlign: 'center', margin: '20px', padding:'10px', backgroundColor:'#3b82f6' }}>
          <h1 className="text-center text-3xl mb-4 text-white" style={{
            margin: '1rem'
          }}>Search on Google </h1>
          <form action="https://www.google.com/search" method="get" style={{
            marginBottom: '0.3rem'
          }}>
            <input
              type="text"
              name="q"
              placeholder="Search..."
              style={{ padding: '10px', marginTop:'1rem',width: '420px', borderRadius: '40rem', border: '1px solid #ccc' }}
            />
            <button
              type="submit"
              style={{ padding: '10px', marginTop:'1rem', marginLeft: '5px', borderRadius: '5px', border: 'none', backgroundColor: 'white' }}
            >
              Search
            </button>
          </form>
        </div>
        )};
    

      <h3 className="text-center text-3xl mb-4" style={{ margin: '1rem' }}>Find Your Perfect Links Here</h3>


      {/* Search Input */}
      <div className="mb-4" style={{
        marginLeft: '6rem'
      }}>
        <input
          type="text"
          placeholder="Search links..."
          value={searchQuery}
          style={{
            width: '15rem',
            margin: '1rem',
            height: '2.4rem',
            justifyContent: 'center',
            border: '1px solid black'
            
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />

<Link
  to="/dashboard/link-groups"
  className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-button justify-center font-semibold text-sm h-36 px-18"
>
  <svg
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    data-testid="AddOutlinedIcon"
    className="svg-icon m-auto -ml-4 mr-8 icon-sm"
    height="1em"
    width="1em"
  >
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
  </svg>
  Add link
</Link>
<Link
  to="/trending"
  className="focus-visible:ring text-white ml-4 text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-button justify-center font-semibold text-sm h-36 px-18"
>

Trending

&nbsp;
<svg
  aria-hidden="true"
  focusable="false"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  data-testid="TrendingUpIcon"
  className="svg-icon m-auto icon-sm"
  height="1em"
  width="1em"
  style={{ marginTop: '10px' }} 
>
  <path d="M16 6l-5 5-2.5-2.5L2 15.5 3.5 17l5-5L12 14l6-6v3h2V4h-5v2h3z"/>
</svg>


</Link>


      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-4">
        <div className="col-span-1 p-4">
          {linkGroups.map((group) => (
            <a
              className={`block text-right py-2 px-4 rounded ${selectedGroup === group.id ? 'focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark justify-center font-semibold ' : ''}`} // Add background for active group
              key={group.id}
              onClick={() => handleTabClick(group.id)}
            >
              {group.name}
            </a>
          ))}
        </div>

        <div className="col-span-11 p-4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <span>Loading...</span>
            </div>
          ) : filteredLinks.length === 0 ? (
            <div className="flex justify-center items-center h-full">
              <span>No links found.</span>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {filteredLinks.map((link) => (
                <LinkCard link={link}/>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkGroupTabs;
